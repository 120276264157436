<div class="confirm_dialog_cont" [ngStyle]="{'position': data && data.from =='MARKETPLACE_SOLUTIONS' ? 'relative' : ''}">
  <!-- DIALOG HEADER -->
  <div  class="dialog_header"  [ngClass]="{'dialog_header-text': data.from =='USER_PREFERENCES' || data.from =='PROJECT_USERS_DELETE' || data.from =='ADMIN_DELETE' || data.from =='CREATE_PROJECT'  || data.from =='PROJECT_SOFT_DELETE' || data.from == 'GENERATE_SPRINT' || data.from =='DELETE_ALL_NOTIFICATIONS' || data.from == 'DOWNLOAD_USER_STORY_SPRINT' || data.from == 'PROVISION_TOOL' || data.from == 'DOWNLOAD_REPORTS'|| data.from == 'MSTEAMS_PROVISION' || data.from == 'MSTEAMS_SWAP' || data.from == 'MSTEAMS_SWAP_CONFIRMDATA' , 'message-text-hide': data.messageText ==''}">
    <p class="title" mat-dialog-title  [ngClass]="{'title-text': data.from =='CREATE_PROJECT' || data.from =='USER_PREFERENCES' || data.from == 'DOWNLOAD_REPORTS' || data.from == 'MSTEAMS_PROVISION' ||  data.from == 'MSTEAMS_SWAP' || data.from == 'MSTEAMS_SWAP_CONFIRMDATA'}" *ngIf="(data.from =='CREATE_PROJECT' || data.from =='USER_PREFERENCES' || data.from == 'DOWNLOAD_REPORTS' || data.from == 'MSTEAMS_PROVISION' || data.from == 'MSTEAMS_SWAP' || data.from == 'MSTEAMS_SWAP_CONFIRMDATA')">
      {{ (data?.title ? data?.title : 'Global.Common.Label.Message') | translate }}
    </p>
    <p class="title warning" mat-dialog-title *ngIf="data.from == 'RETIRE_TAXONOMY'">
     {{ (data?.title ? data?.title : 'Global.Common.Label.Message') | translate }}
    </p>
    <p class="title" mat-dialog-title  [ngClass]="{'title-text delete-user-text': data.from =='PROJECT_USERS_DELETE'}" *ngIf="data.from =='PROJECT_USERS_DELETE'">
      {{ (data?.title ? data?.title : 'Global.Common.Label.Message') | translate}}
    </p>
    <p class="title" mat-dialog-title  [ngClass]="{'title-text delete-user-text': data.from =='ADMIN_DELETE'}" *ngIf="data.from =='ADMIN_DELETE'">
      {{ (data?.title ? data?.title : 'Global.Common.Label.Message') | translate}}
    </p>
    <p class="title" mat-dialog-title  [ngClass]="{'title-text delete-user-text': data.from =='PROJECT_SOFT_DELETE' || data.from =='DELETE_ALL_NOTIFICATIONS'}" *ngIf="data.from =='PROJECT_SOFT_DELETE' || data.from =='DELETE_ALL_NOTIFICATIONS'">
      {{ (data?.title ? data?.title : 'Global.Common.Label.Message') | translate }}
    </p>
    <p class="title" mat-dialog-title  [ngClass]="{'title-text delete-user-text': data.from == 'DOWNLOAD_USER_STORY_SPRINT'}" *ngIf=" data.from == 'DOWNLOAD_USER_STORY_SPRINT'">
      {{ (data?.title ? data?.title : 'Global.Common.Label.Message') | translate }}
    </p>
    <p class="title" mat-dialog-title  [ngClass]="{'title-text delete-user-text': data.from == 'GENERATE_SPRINT'}" *ngIf=" data.from == 'GENERATE_SPRINT'">
      {{ (data?.title ? data?.title : 'Global.Common.Label.Message') | translate }}
    </p>
    <p class="title title-text delete-user-text" mat-dialog-title *ngIf=" data.from == 'PROVISION_TOOL'">
      {{ (data?.title ? data?.title : 'Global.Common.Label.Message') | translate }}
    </p>
    <p class="title" mat-dialog-title   *ngIf="(data.from !='PROJECT_USERS_DELETE' && data.from !='USER_PREFERENCES' && data.from !='CREATE_PROJECT' && data.from !='PROJECT_SOFT_DELETE' && data.from !='ADMIN_DELETE' && data.from != 'DOWNLOAD_USER_STORY_SPRINT' && data.from != 'PROVISION_TOOL' && data.from != 'GENERATE_SPRINT' && data.from != 'RETIRE_TAXONOMY' && data.from !='DELETE_ALL_NOTIFICATIONS' && data.from != 'DOWNLOAD_REPORTS' && data.from != 'MSTEAMS_PROVISION' && data.from != 'MSTEAMS_SWAP' && data.from != 'MSTEAMS_SWAP_CONFIRMDATA' )">
      {{ (data?.title ? data?.title : 'Global.Common.Label.Message') | translate }}
    </p>
  
    <i class="icon close"  *ngIf="userPrefDataFound" (click)="closePopup()" [ngClass]="{'close-popup': data.from =='USER_PREFERENCES'}"></i>
    <i class="icon close" *ngIf="data.from =='CREATE_PROJECT'"   (click)="closePopup()" [ngClass]="{'close-popup': data.from =='CREATE_PROJECT'}"></i>
    <i class="icon close"  *ngIf="data.from =='PROJECT_USERS_DELETE' || data.from =='PROJECT_SOFT_DELETE' || data.from =='ADMIN_DELETE' || data.from == 'DOWNLOAD_USER_STORY_SPRINT' || data.from == 'GENERATE_SPRINT'" (click)="closePopup()" [ngClass]="{'close-popup': data.from =='PROJECT_USERS_DELETE' || data.from =='PROJECT_SOFT_DELETE' || data.from =='ADMIN_DELETE' || data.from == 'DOWNLOAD_USER_STORY_SPRINT' || data.from == 'GENERATE_SPRINT'}"></i>
    <i class="icon close popup"  *ngIf="data.from == 'PROVISION_TOOL' || data.from == 'DELETE_ALL_NOTIFICATIONS'" (click)="closePopup()" [ngClass]="{'close-popup': data.from == 'PROVISION_TOOL' || data.from == 'DELETE_ALL_NOTIFICATIONS'}"></i>
    <i class="icon close popup"  *ngIf="data.from == 'DOWNLOAD_REPORTS' || data.from == 'MSTEAMS_PROVISION' || data.from == 'MSTEAMS_SWAP' || data.from == 'MSTEAMS_SWAP_CONFIRMDATA'" (click)="closePopup()" [ngClass]="{'close-popup-icon': data.from == 'DOWNLOAD_REPORTS' || data.from == 'MSTEAMS_PROVISION' || data.from == 'MSTEAMS_SWAP' || data.from == 'MSTEAMS_SWAP_CONFIRMDATA'}"></i>
    <hr class="divider" *ngIf="data.from =='USER_PREFERENCES' || data.from =='PROVISION_TOOL' || data.from == 'DOWNLOAD_REPORTS' || data.from == 'MSTEAMS_PROVISION' || data.from == 'MSTEAMS_SWAP' || data.from == 'MSTEAMS_SWAP_CONFIRMDATA'"/>
  </div>



  <!-- DIALOG CONTENT -->
  <mat-dialog-content class="mat-typography" [ngClass]="{'user-pref-padding': data && (data.from =='USER_PREFERENCES' || data.from =='CREATE_PROJECT'), 'delete-project-user-padding': data && data.from == 'PROJECT_USERS_DELETE' || data.from == 'PROJECT_SOFT_DELETE' || data.from =='ADMIN_DELETE' || data.from == 'DOWNLOAD_USER_STORY_SPRINT' || data.from == 'PROVISION_TOOL'  ||  data.from == 'RETIRE_TAXONOMY' || data.from == 'GENERATE_SPRINT' }">
    <p *ngIf="data.from !== 'DELETE_ALL_NOTIFICATIONS'" class="status_txt" [ngClass]="{'market-solution-msg': data && data.from =='MARKETPLACE_SOLUTIONS'}">{{data.message | translate}}</p>
    <p *ngIf="data.from !== 'DELETE_ALL_NOTIFICATIONS'" class="status_txt" [ngClass]="{'user-pref-txt': data && data.from == 'USER_PREFERENCES'}">{{data.messageText | translate}}</p>
    <p class="status_txt" *ngIf="data.from =='PROJECT_USERS_DELETE' " [ngClass]="{'delete-project-user-txt': data && (data.from == 'PROJECT_USERS_DELETE')}">{{data.deleteMessageText | translate}}</p>
    <p class="status_txt delete_status_txt" *ngIf="data.from == 'DELETE_ALL_NOTIFICATIONS'">{{data.deleteMessageText | translate}}</p>
    <p class="status_txt" *ngIf=" data.from =='PROJECT_SOFT_DELETE'" [ngClass]="{'delete-project-user-txt': data && data.from == 'PROJECT_SOFT_DELETE'}">{{data.projectName}} {{data.deleteMessageText | translate}}</p>
    <p class="status_txt" *ngIf=" data.from =='ADMIN_DELETE'" [ngClass]="{'delete-project-user-txt': data && data.from == 'ADMIN_DELETE'}">{{data.deleteMessageText}} {{data?.deleteAdminId}} +' '+{{'Shared.CommonDialogBox.label.Admin' | translate}}?</p>
    <p class="status_txt" *ngIf=" data.from =='DOWNLOAD_USER_STORY_SPRINT'" [ngClass]="{'delete-project-user-txt': data && data.from == 'DOWNLOAD_USER_STORY_SPRINT'}">{{data.downloadMessageText | translate}}</p>
    <p class="status_txt delete-project-user-txt" *ngIf="data.from =='PROVISION_TOOL' && !data.provisionMessage2" [ngClass]="{'delete-project-user-txt': data && data.from == 'PROVISION_TOOL'}">{{data.provisionMessage | translate}}</p>
    <p  *ngIf="data.from =='MSTEAMS_PROVISION'" [ngClass]="{'delete-project-user-txt': data && data.from == 'MSTEAMS_PROVISION'}">{{data.msprovisionMessage | translate}}</p>
    <div *ngIf="(data.from == 'MSTEAMS_SWAP' ) && !IsCurrentMSTeamsOwner && !(data.from == 'MSTEAMS_SWAP' && !IsTargetSiteOwner && !IsCurrentMSTeamsOwner)" class="msteamsswaperror-desc">
      <img src="../../../../assets/msteamsswap_dangeralert.svg">
      <span>
{{'Shared.CommonDialogBox.Label.MSTeamsSwap.OwnershipAccessToLinkedSite' | translate}}      </span>
    </div>
    
    <div *ngIf="(data.from == 'MSTEAMS_SWAP' ) && !IsTargetSiteOwner && !(data.from == 'MSTEAMS_SWAP' && !IsTargetSiteOwner && !IsCurrentMSTeamsOwner)" class="msteamsswaperror-desc">
      <img src="../../../../assets/msteamsswap_dangeralert.svg">
      <span>
{{'Shared.CommonDialogBox.Label.MSTeamsSwap.OwnershipAccessToTargetSite' | translate}}   
   </span>
    </div>
   
    <div *ngIf="(data.from == 'MSTEAMS_SWAP' || data.from == 'MSTEAMS_SWAP_CONFIRMDATA') && !IsTargetSiteOwner && !IsCurrentMSTeamsOwner" class="msteamsswaperror-desc">
      <img src="../../../../assets/msteamsswap_dangeralert.svg">
      <span>
{{'Shared.CommonDialogBox.Label.MSTeamsSwap.OwnershipAccessToCurrentAndTargetSite' | translate}}   
   </span>
    </div>
    
    <p  *ngIf="data.from =='MSTEAMS_SWAP' && sourceTeamName" [ngClass]="{'current-linked-site-text': data && data.from == 'MSTEAMS_SWAP'}">{{'Shared.CommonDialogBox.Label.MSTeamsSwap.SourceHeading' | translate}}</p>
    
    <p  *ngIf="data.from =='MSTEAMS_SWAP_CONFIRMDATA' && transferDataFromMsTeamName" [ngClass]="{'current-linked-site-text': data && data.from == 'MSTEAMS_SWAP_CONFIRMDATA'}">{{'Shared.CommonDialogBox.Label.MSTeamsSwap.TransferDataFrom' | translate}}</p>
    <ul *ngIf="data.from =='PROVISION_TOOL' && data.tool == 'Smart Offering' && data.provisionMessage2">
      <li class="status_txt delete-project-user-txt">
        <p [ngClass]="{'delete-project-user-txt': data && data.from == 'PROVISION_TOOL'}">{{data.provisionMessage | translate}}</p>
      </li>
      <li class="status_txt delete-project-user-txt">
        <p [ngClass]="{'delete-project-user-txt': data && data.from == 'PROVISION_TOOL'}">{{data.provisionMessage2 | translate}}</p>
      </li>
    </ul>

    <p class="status_inner" *ngFor="let item of data?.messageItems; let i=index"
    [ngClass]="{'user-pref-txt': data && ( data.from =='CREATE_PROJECT'),
    'status_inner_create': data && ( data.from =='CREATE_PROJECT'),
    'status_inner': data && ( data.from !='CREATE_PROJECT')
  }">
     <spam  *ngIf="data.from !='CREATE_PROJECT' && data.from !='PROVISION_TOOL'">{{ i+1}} . {{item}}</spam>
     <spam class="status_txt delete-project-user-txt" *ngIf="data.from =='PROVISION_TOOL'">{{ i+1}} . {{item}}</spam>
     <spam  *ngIf="data.from =='CREATE_PROJECT'">{{item}}</spam>
    </p>

    <p class="status_txt" [hidden]="data.from =='PROVISION_TOOL'" *ngFor="let error of data?.errors | keyvalue: originalOrder; let i = index">{{ error.value | translate }}</p>
    <p  *ngIf="data.from =='PROVISION_TOOL'">
      <spam class="status_txt delete-project-user-txt" *ngFor="let error of data?.errors | keyvalue: originalOrder; let i = index">{{ error.value | translate}}</spam>

    </p>

    <div *ngIf="data.from =='MSTEAMS_SWAP' && sourceTeamName" class="div_bgcolor">
       <p class="exitsing_site_text">{{sourceTeamName}}</p>
    </div>
    <div *ngIf="data.from =='MSTEAMS_SWAP_CONFIRMDATA' && transferDataFromMsTeamName" class="div_bgcolor margin-bottom-16">
      <p class="exitsing_site_text">{{transferDataFromMsTeamName}}</p>
   </div>
    <div *ngIf="data.from =='MSTEAMS_SWAP' && IsGenerativeAIFolderFound" class="size_text" >
      <p >{{'Shared.CommonDialogBox.Label.MSTeamsSwap.SizeOfGenerativeAI'| translate}} {{ ascendGenerativeAISize }}</p>
   </div>
   <p  *ngIf="data.from =='MSTEAMS_SWAP_CONFIRMDATA' && transferDataToMsTeamName" [ngClass]="{'current-linked-site-text': data && data.from == 'MSTEAMS_SWAP_CONFIRMDATA'}">{{'Shared.CommonDialogBox.Label.MSTeamsSwap.TransferDataTo' | translate}}</p>
   <div *ngIf="data.from =='MSTEAMS_SWAP_CONFIRMDATA' && transferDataToMsTeamName" class="div_bgcolor margin-bottom-16">
    <p class="exitsing_site_text">{{transferDataToMsTeamName}}</p>
    </div>
    <div *ngIf="(data?.title == 'Activities.ToolsProvision.DeliveryTools.ECToolProvision.Title')">
      <h4>{{'Activities.ToolsProvision.DeliveryTools.ECToolProvision.Step1' | translate}}</h4>
      <div class="tool-status" [ngClass]="(data.provisionSteps.engagementStatus == 'Requested') ? 'requested' : (data.provisionSteps.engagementStatus=='Granted') ? 'granted' : (data.provisionSteps.engagementStatus == 'Error') ? 'error' :''">
        <span class="status-provision-text" >{{data.provisionSteps.engagementStatus}}</span>
      </div>
      <div *ngIf="data.provisionSteps.engagementStatus == 'Error'" class="error-desc">
        <img src="../../../../assets/images/ErrorDescription.svg"><span>
        {{'Activities.ToolsProvision.DeliveryTools.ECToolProvision.Step1.Error' | translate}}
      </span>
      </div>
      <h4>{{'Activities.ToolsProvision.DeliveryTools.ECToolProvision.Step2' | translate}}</h4>
      <div class="tool-status" [ngClass]="(data.provisionSteps.taxonomyStatus == 'Requested') ? 'requested' : (data.provisionSteps.taxonomyStatus == 'Granted') ? 'granted' : (data.provisionSteps.taxonomyStatus == 'Error') ? 'error' :''">
        <span class="status-provision-text">{{data.provisionSteps.taxonomyStatus}}</span>
      </div>
      <div *ngIf="data.provisionSteps.taxonomyStatus == 'Error'" class="error-desc">
        <img src="../../../../assets/images/ErrorDescription.svg"><span>
        {{'Activities.ToolsProvision.DeliveryTools.ECToolProvision.Step2.Error' | translate}}
      </span>
      </div>
      <ng-container *ngIf="data.provisionSteps.hasOwnProperty('techObjStatus')">
      <h4 >{{'Activities.ToolsProvision.DeliveryTools.ECToolProvision.Step3' | translate}}</h4>
      <div class="tool-status" [ngClass]="(data.provisionSteps.techObjStatus == 'Requested') ? 'requested' : (data.provisionSteps.techObjStatus == 'Granted') ? 'granted' : (data.provisionSteps.techObjStatus == 'Error') ? 'error' :''">
        <span class="status-provision-text">{{data.provisionSteps.techObjStatus}}</span>
      </div>
      <div *ngIf="data.provisionSteps.techObjStatus == 'Error'" class="error-desc">
        <img src="../../../../assets/images/ErrorDescription.svg"><span>
        {{'Activities.ToolsProvision.DeliveryTools.ECToolProvision.Step3.Error' | translate}}
      </span>
      </div>
     </ng-container>
    </div>

    <div *ngIf="(data?.title == Test_AI_PROVISION_TITLE)">
      <h4>{{'Activities.ToolsProvision.DeliveryTools.UpdatingToTestAI.Step1' | translate}}</h4>
      <div class="tool-status" [ngClass]="(data.provisionSteps.projCreationStatus == 'Requested') ? 'requested' : (data.provisionSteps.projCreationStatus=='Granted') ? 'granted' : (data.provisionSteps.projCreationStatus == 'Error') ? 'error' :''">
        <span class="status-provision-text" >{{data.provisionSteps.projCreationStatus}}</span>
      </div>
      <div *ngIf="data.provisionSteps.projCreationStatus == 'Error'" class="error-desc">
        <img src="../../../../assets/images/ErrorDescription.svg"><span>
        {{'Activities.ToolsProvision.DeliveryTools.UpdatingToTestAI.Step1.Error' | translate}}
      </span>
      </div>
      <h4>{{'Activities.ToolsProvision.DeliveryTools.UpdatingToTestAI.Step2' | translate}}</h4>
      <div class="tool-status" [ngClass]="(data.provisionSteps.taxonomyStatus == 'Requested') ? 'requested' : (data.provisionSteps.taxonomyStatus == 'Granted') ? 'granted' : (data.provisionSteps.taxonomyStatus == 'Error') ? 'error' :''">
        <span class="status-provision-text">{{data.provisionSteps.taxonomyStatus}}</span>
      </div>
      <div *ngIf="data.provisionSteps.taxonomyStatus == 'Error'" class="error-desc">
        <img src="../../../../assets/images/ErrorDescription.svg"><span>
        {{'Activities.ToolsProvision.DeliveryTools.UpdatingToTestAI.Step2.Error' | translate}}
      </span>
      </div>
        <h4>{{'Activities.ToolsProvision.DeliveryTools.UpdatingToTestAI.Step3' | translate}}</h4>
        <div class="tool-status"
          [ngClass]="(data.provisionSteps.userStoriesSyncStatus == 'Requested') ? 'requested' : (data.provisionSteps.userStoriesSyncStatus == 'Granted') ? 'granted' : (data.provisionSteps.userStoriesSyncStatus == 'Error') ? 'error' :''">
          <span class="status-provision-text">{{data.provisionSteps.userStoriesSyncStatus}}</span>
        </div>
        <div *ngIf="data.provisionSteps.userStoriesSyncStatus == 'Error'" class="error-desc">
          <img src="../../../../assets/images/ErrorDescription.svg"><span>
            {{'Activities.ToolsProvision.DeliveryTools.UpdatingToTestAI.Step3.Error' | translate}}
          </span>
        </div>
    </div>

    <div *ngIf="(data?.title == EC_SYNC_INFO_POPUP?.title)">
      <h4>{{ EC_SYNC_INFO_POPUP?.step1 | translate}}</h4>
      <div class="tool-status" *ngIf="data?.provisionSteps?.attribute3" [ngClass]="data?.provisionSteps?.attribute3?.toLowerCase().trim()">
        <span class="status-provision-text" >{{EC_SYNC_STATUS_COLOR_CODE_MAP[data?.provisionSteps?.attribute3?.toLowerCase().trim()]?.display_text | translate}}</span>
      </div>
      <div *ngIf="EC_SYNC_STATUS_COLOR_CODE_MAP[data?.provisionSteps?.attribute3?.toLowerCase()?.trim()] == 'Error'?.toLowerCase() || EC_SYNC_STATUS_COLOR_CODE_MAP[data?.provisionSteps?.attribute3?.toLowerCase().trim()] == 'Failed'?.toLowerCase()" class="error-desc">
        <img src="../../../../assets/images/ErrorDescription.svg"><span>{{ EC_SYNC_INFO_POPUP?.step1_error | translate}}</span>
      </div>
      <h4>{{ EC_SYNC_INFO_POPUP?.step2 | translate }}</h4>
      <div class="tool-status" *ngIf="data?.provisionSteps?.attribute4" [ngClass]="data?.provisionSteps?.attribute4?.toLowerCase().trim()">
        <span class="status-provision-text">{{EC_SYNC_STATUS_COLOR_CODE_MAP[data?.provisionSteps?.attribute4?.toLowerCase().trim()]?.display_text | translate}}</span>
      </div>
      <div *ngIf="EC_SYNC_STATUS_COLOR_CODE_MAP[data?.provisionSteps?.attribute4?.toLowerCase().trim()] == 'Error'?.toLowerCase() || EC_SYNC_STATUS_COLOR_CODE_MAP[data?.provisionSteps?.attribute4?.toLowerCase().trim()] == 'Failed'?.toLowerCase()" class="error-desc">
        <img src="../../../../assets/images/ErrorDescription.svg"><span>{{ EC_SYNC_INFO_POPUP?.step2_error | translate}}</span>
      </div>
    </div>

    <div *ngIf="(data?.title == 'Activities.ToolsProvision.DeliveryTools.SOToolProvision.Title')">
      <h4>{{'Activities.ToolsProvision.DeliveryTools.SOToolProvision.Step1' | translate}}</h4>
      <div class="tool-status" [ngClass]="(data.provisionSteps.engagementStatus == 'Requested') ? 'requested' : (data.provisionSteps.engagementStatus=='Granted') ? 'granted' : (data.provisionSteps.engagementStatus == 'Error') ? 'error' :''">
        <span class="status-provision-text" >{{data.provisionSteps.engagementStatus}}</span>
      </div>
      <div *ngIf="data.provisionSteps.engagementStatus == 'Error'" class="error-desc">
        <img src="../../../../assets/images/ErrorDescription.svg"><span>{{'Activities.ToolsProvision.DeliveryTools.SOToolProvision.Step1.Error' | translate}}</span>
      </div>
    </div>

    <div *ngIf="data?.content" class="afterrow"> <p class="status_txt three-column-layout" *ngFor="let sprint of data?.content ">{{ sprint.sprint_name + " to " + sprint.planned_capacity_percentage+"%" }}</p></div>
    <button mat-raised-button (click)="performAction()" *ngIf="data?.contentButtonText" class="btn-content">
      {{ data?.contentButtonText | translate}}
    </button>

    <div class="download-report" *ngIf="data.from == 'MSTEAMS_PROVISION'">
      <mat-radio-group   class='radio-group-channels' >
        <mat-radio-button (change)="radioButtonChanged($event)"  *ngFor="let channel of msTeamsChannelTypes" [value]="channel.id" [checked]="channel.selected">{{channel.name | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngIf="data.from == 'MSTEAMS_PROVISION' && ishidden">
      <form>
        <div class="exist-channel">
          <mat-label style="font-weight: bold;">
            {{'Shared.CommonDialogBox.DeliveryTool.Label.ExistinglSite' | translate}} </mat-label>
        </div>
        <div>
          <div class="ms-teams-request-input">
          <mat-form-field appearance="outline" floatLabel="never" 
                    [ngClass]="{'disable-element' : projectReviewScreen || isExpiredProject}" style="width: 25rem;">
                    <input class="custom-input" style="width: 96%;" #searchTxt matAutocompletePosition="below"
                      (click)="onPanelFocus(searchTxt.value)" (input)="filterOwenedChannels($event)" matInput
                      formControlName="msChannel"
                      [placeholder]="'Shared.CommonDialogBox.DeliveryTool.Label.SelectExistChannelSite' | translate"
                      [matAutocomplete]="autoLeadCountry"  />
                    <mat-icon style="background-color: transparent !important;font-size: 96%; color: #959EA9;"
                    (click)="toggleDropdown()" matSuffix>
                      <span style="border-left: 2px solid #EEEEEE;position: relative; top: -2px;">&nbsp;</span>
                      {{isDropdownOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
                    <mat-autocomplete #autoLeadCountry="matAutocomplete" (closed)="onPanelClosed()"
                      (optionSelected)="isDropdownOpen = false">
                      <ng-container style="border: 1px solid #D1D3D6; width: 98%; max-height: 215 px;background-color: red;"
                      *ngIf="isDropdownOpen">
                      <mat-option style="color: #272727;height: 24px;font-weight: bold;font-size: 12px;text-align: center;padding: 0px 4px 0px 4px;"
                        *ngIf="isDataFetchingInProgress" disabled>Loading MS Teams Sites to be linked to the Project</mat-option>
                      <mat-option style="color: #272727;height: 30px;"
                        *ngIf="filteredChannels?.length==0 && isDataFetchingInProgress === false" disabled>No results found</mat-option>
                      <img *ngIf="showLoader" src="assets/Loading.svg" style="margin-left: 45%;margin-top: 48px;margin-bottom: 48px;background-color: #F4F4F5;border-radius: 4px;" alt="Loading...">
                      <mat-option [ngClass]="{'selected-option': isOptionSelected(option)}"
                      style="height: 35px;font-weight: bold;font-size: 14px;" *ngFor="let option of filteredChannels" [value]="option" (click)="onMsChannelSelect(option)">
                         {{option}}
                      </mat-option>
                    </ng-container>
                    </mat-autocomplete>
                  </mat-form-field>
                  </div>
        </div>
      
    </form>
   </div>

   <!----------Msteams Swap link teams site ------>
   <div *ngIf="data.from == 'MSTEAMS_SWAP' && IsCurrentMSTeamsOwner && sourceTeamName" class="margin-top-15">
    <form>
      <div class="exist-channel">
        <mat-label style="font-weight: bold;">
          <!-- {{'Shared.CommonDialogBox.DeliveryTool.Label.ExistinglSite' | translate}} -->
        {{'Shared.CommonDialogBox.Label.MSTeamsSwap.TargetHeading' | translate}}
        </mat-label>
      </div>
      <div class="margin-top-5">
        <div class="ms-teams-request-input">
          <mat-form-field appearance="outline" floatLabel="never" 
                          [ngClass]="{'disable-element' : projectReviewScreen || isExpiredProject, 'responsive-width': true}">
            <input class="custom-input responsive-input" #searchTxt matAutocompletePosition="below"
                   (click)="onPanelFocus(searchTxt.value)" (input)="filterOwenedChannels($event)" (blur)="onBlur($event)" matInput
                   formControlName="msChannel"
                   [placeholder]="'Shared.CommonDialogBox.DeliveryTool.Label.SelectExistChannelSite' | translate"
                   [matAutocomplete]="autoLeadCountry" />
            <mat-icon style="background-color: transparent !important;font-size: 96%; color: #959EA9;"
                      (click)="toggleDropdown()" matSuffix>
              <span style="border-left: 2px solid #EEEEEE;position: relative; top: -2px;">&nbsp;</span>
              {{isDropdownOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
            </mat-icon>
            <mat-autocomplete #autoLeadCountry="matAutocomplete" (closed)="onPanelClosed()"
                              (optionSelected)="isDropdownOpen = false">
              <ng-container style="border: 1px solid #D1D3D6; width: 98%; max-height: 215px; background-color: red;"
                            *ngIf="isDropdownOpen">
                <mat-option style="color: #272727; height: 24px; font-weight: bold; font-size: 12px; text-align: center; padding: 0px 4px 0px 4px;"
                            *ngIf="isDataFetchingInProgress" disabled>Loading MS Teams Sites to be linked to the Project</mat-option>
                <mat-option style="color: #272727; height: 30px;"
                            *ngIf="filteredChannels?.length == 0 && isDataFetchingInProgress === false" disabled>No results found</mat-option>
                <img *ngIf="showLoader" src="assets/Loading.svg" style="margin-left: 45%; margin-top: 48px; margin-bottom: 48px; background-color: #F4F4F5; border-radius: 4px;" alt="Loading...">
                <mat-option [ngClass]="{'selected-option': isOptionSelected(option)}"
                            style="height: 35px; font-weight: bold; font-size: 14px;" *ngFor="let option of filteredChannels" [value]="option" (click)="onMsSweepChannelSelect(option)">
                  {{option}}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    
  </form>
 </div>

 <div *ngIf="data.from =='MSTEAMS_SWAP' && isMoreThan10GB" class="msteamsswapalerterror-desc" style="display: flex;">
  
  <img src="../../../../assets/msteamsswap_alert.svg" style="width: 24px; height: 20px; padding: 1px 8px 0px 0px; gap: 8px;">

<div>
  <strong>{{'Shared.CommonDialogBox.Label.MSTeamsSwap.Exceeded10GBTeamsSiteLimit'| translate}}.</strong>
  <br>
  
    {{'Shared.CommonDialogBox.Label.MSTeamsSwap.Largerthan10GBText' | translate}}
  
</div>
</div>
<div *ngIf="data.from == 'MSTEAMS_SWAP' && isMoreThan10GB" class="checkbox-container">
  <input type="checkbox" id="dataMigratedCheckbox" [(ngModel)]="isDataMigrated" (ngModelChange)="onCheckboxChange($event)">
  <span><strong>{{'Shared.CommonDialogBox.Label.MSTeamsSwap.MigratedDataManually' | translate}}</strong></span>
</div>

<div *ngIf="data.from == 'MSTEAMS_SWAP_CONFIRMDATA'&& IsCurrentMSTeamsOwner && IsTargetSiteOwner && transferDataFromMsTeamName && transferDataToMsTeamName" class="checkbox-container">
  <input type="checkbox" id="dataMigratedConfirmCheckbox" [(ngModel)]="isDataMigratedConfirm" (ngModelChange)="onCheckboxConfirmChange($event)">
  <span><strong>{{'Shared.CommonDialogBox.Label.MSTeamsSwap.MigratedDataManually' | translate}}</strong></span>
</div>
    <!----------Auto reporting code begins------>

    <div class="download-report" *ngIf="data.from == 'DOWNLOAD_REPORTS'">
      <mat-label class="label-text">{{'Shared.CommonDialogBox.AutoReporting.Label.SelectReports' | translate}}</mat-label>
      <mat-radio-group (change)="validateReportInput()" [(ngModel)]="projectPackagesAndStatus.typeOfReports.currentSelected" class='radio-group-reports'>
        <mat-radio-button [disabled]="!typeReport.active" [checked]="projectPackagesAndStatus.typeOfReports.currentSelected == typeReport" *ngFor="let typeReport of projectPackagesAndStatus.typeOfReports.data" [value]="typeReport.name">{{typeReport.name}}</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="download-report" *ngIf="data.from == 'DOWNLOAD_REPORTS'" style="margin-top: 10px">
      <mat-label class="label-text">{{'Shared.CommonDialogBox.AutoReporting.Label.SelectOffering' | translate}}</mat-label>
      <br>
      <h4 class="sub-label-text label-whitespace">{{'Shared.CommonDialogBox.AutoReporting.Label.Offering' | translate}}</h4>
      <mat-form-field class="form-field-option" appearance="outline" floatLabel="never">
        <i class="fas fa-chevron-down"></i>
        <img matSuffix src="../../../../assets/images/filter-pipe.png" width="1px" height="20px">
        <mat-select panelClass="auto-report-multiselect-dropdown" multiple [value]="projectPackagesAndStatus.currentPackage" name="selectOffering" class="placeholder-text" placeholder="Select Offering/Package" disableOptionCentering>
          <mat-select-trigger>
            <mat-chip-list>
              <mat-chip (removed)="removeChip(obj.name,'PACKAGE')" [hidden]="!obj.completed || i > 2" *ngFor="let obj of getOnlySelected('PACKAGE');let i=index" >
                  {{obj.name}}
                  <mat-icon class="close-icon" matChipRemove>close</mat-icon>
              </mat-chip>
                <h3 [matTooltip]="getTootipTextForExtra('PACKAGE')" matTooltipClass="mat-tooltip-extra-selection" *ngIf="getTotalSelected('PACKAGE')>3" class="more-plus">+{{getTotalSelected('PACKAGE')-3}}</h3>
            </mat-chip-list>
          </mat-select-trigger>

          <mat-option class="mat-opt-text" value="projectPackagesAndStatus.packages.allCompleted">
            <mat-checkbox [checked]="projectPackagesAndStatus.packages.allCompleted" [indeterminate]="checkSelectedStatus('PACKAGE')" (change)="selectAllStatus($event.checked,'PACKAGE')">{{'Global.Common.Label.SelectAll' | translate}}</mat-checkbox>
          </mat-option>
          <mat-option  class="mat-opt-text" *ngFor="let obj of projectPackagesAndStatus.packages.packageList; let i=index" [value]="obj.name">
            <mat-checkbox [(ngModel)]="obj.completed"  (ngModelChange)="updateAllCompleted('PACKAGE')">{{obj.name}}</mat-checkbox>
          </mat-option>
       </mat-select>
        </mat-form-field>
    </div>

    <div class="download-report" *ngIf="data.from == 'DOWNLOAD_REPORTS'">
      <mat-label class="label-text">{{'Shared.CommonDialogBox.AutoReporting.Label.SelectStatus' | translate}}</mat-label>
      <br>
      <h4 class="sub-label-text label-whitespace">{{'Shared.CommonDialogBox.AutoReporting.Label.ProjectStatus' | translate}}</h4>
      <mat-form-field class="form-field-option" appearance="outline" floatLabel="never">
        <i class="fas fa-chevron-down"></i>
        <img matSuffix src="../../../../assets/images/filter-pipe.png" width="1px" height="20px">
        <mat-select panelClass="auto-report-multiselect-dropdown" multiple [value]="projectPackagesAndStatus.currentStatus" class="placeholder-text" placeholder="Select Project Status" disableOptionCentering>
          <mat-select-trigger>
            <mat-chip-list>
              <mat-chip (removed)="removeChip(obj.status,'STATUS')" [hidden]="!obj.completed || i > 2" *ngFor="let obj of getOnlySelected('STATUS');let i=index" >
                  {{obj.displayText}}
                  <mat-icon class="close-icon" matChipRemove>close</mat-icon>
              </mat-chip>
              <h3 [matTooltip]="getTootipTextForExtra('STATUS')" matTooltipClass="mat-tooltip-extra-selection" class="more-plus" *ngIf="getTotalSelected('STATUS')>3">+{{getTotalSelected('STATUS')-3}}</h3>
            </mat-chip-list>
          </mat-select-trigger>
          <mat-option class="mat-opt-text" value="projectPackagesAndStatus.statusOfProjects.allCompleted">
            <mat-checkbox [checked]="projectPackagesAndStatus.statusOfProjects.allCompleted" [indeterminate]="checkSelectedStatus('STATUS')" (change)="selectAllStatus($event.checked,'STATUS')">{{'Global.Common.Label.SelectAll' | translate}}</mat-checkbox>
          </mat-option>
          <mat-option  class="mat-opt-text" *ngFor="let obj of projectPackagesAndStatus.statusOfProjects.projectStatus; let i=index" [value]="obj.status">
            <mat-checkbox [(ngModel)]="obj.completed"  (ngModelChange)="updateAllCompleted('STATUS')">{{obj.displayText}}</mat-checkbox>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="download-report" *ngIf="data.from == 'DOWNLOAD_REPORTS'">
      <mat-label class="label-text">{{'Shared.CommonDialogBox.AutoReporting.Label.SpecifyDate' | translate }}</mat-label>
      <mat-radio-group (change)="validateReportInput()" [(ngModel)]='projectPackagesAndStatus.dateRangeOfReports.currentSelected' class='radio-group-reports' *ngFor="let dateType of projectPackagesAndStatus.dateRangeOfReports.data; let i = index">
      <div>
        <mat-radio-button matTooltipPosition="right" [matTooltip]="projectPackagesAndStatus.dateRangeOfReports.tooltips[i]" matTooltipClass="mat-tooltip-download-validation" [checked]="projectPackagesAndStatus.dateRangeOfReports.currentSelected == dateType" [value]="dateType">{{dateType}}</mat-radio-button>
      </div>
      </mat-radio-group>
    </div>

    <div class="download-report download-report-date form-group-wrapper" *ngIf="data.from == 'DOWNLOAD_REPORTS' && projectPackagesAndStatus.dateRangeOfReports.currentSelected == 'Custom date'">
           <!--start dtae-->
        <div class="form-group">
           <h4 class="sub-label-text">{{'Global.Common.Label.StartDate' | translate}}</h4>
      <mat-form-field appearance="outline" floatLabel="never" matTooltipPosition="above"
      matTooltipDisabled="false"
     >
      <!--Will be enabled when validation is required on start date-->
      <!-- <input disabled='true' [min]="projectPackagesAndStatus.startDate.minDate" [max]="projectPackagesAndStatus.startDate.maxDate" (dateChange)="changeDate('start')" [(ngModel)]="projectPackagesAndStatus.selectedStartDate" matInput [matDatepicker]="startPicker" placeholder="MM / DD / YYYY"
            > -->
      <input disabled='true' (dateChange)="changeDate('start')" [(ngModel)]="projectPackagesAndStatus.selectedStartDate" matInput [matDatepicker]="startPicker" placeholder="MM / DD / YYYY"
            >
      <mat-datepicker-toggle matSuffix [for]="startPicker">
        <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker disabled='false' #startPicker panelClass="download-report-datepicker"
        >
      </mat-datepicker>
    </mat-form-field>
  </div>
      <!--end date-->
      <div class="form-group">
      <h4 class="sub-label-text">{{'Global.Common.Label.EndDate' | translate}}</h4>
      <mat-form-field appearance="outline" floatLabel="never" matTooltipPosition="above"
      matTooltipDisabled="false"
     >
     <!--Will be enabled when validation is required on end date-->
      <!-- <input disabled='true' [min]="projectPackagesAndStatus.endDate.minDate" [max]="projectPackagesAndStatus.endDate.maxDate" (dateChange)="changeDate('end')" [(ngModel)]="projectPackagesAndStatus.selectedEndDate" matInput [matDatepicker]="endPicker" placeholder="MM / DD / YYYY"
            > -->
      <input disabled='true' (dateChange)="changeDate('end')" [(ngModel)]="projectPackagesAndStatus.selectedEndDate" matInput [matDatepicker]="endPicker" placeholder="MM / DD / YYYY"
            >
      <mat-datepicker-toggle matSuffix [for]="endPicker">
        <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker disabled="false" #endPicker panelClass="download-report-datepicker"
        >
      </mat-datepicker>
    </mat-form-field>
    </div>
    </div>
    <!--AUto reporting code ends-->

    <div class="select-option package-select" *ngIf="data.from =='USER_PREFERENCES'">
      <mat-label class="label-text">{{"Navigation.Menu.UserPreferences.Dialog.Form.Package" | translate}}</mat-label>
      <mat-form-field class="form-field-option" appearance="outline" floatLabel="never">
        <i class="fas fa-chevron-down"></i>
        <img matSuffix src="../../../../assets/images/filter-pipe.png" width="1px" height="20px">
        <mat-select  name="selectOffering" class="placeholder-text" [placeholder]="'Navigation.Menu.UserPreferences.Dialog.Form.Package.Placeholder' | translate" disableOptionCentering
         [(ngModel)]="data.userPrefOffering"
         (selectionChange)="getPackages($event.value)"
         [disabled]="noOfferings">
         <mat-option class="mat-opt-text" *ngFor="let selectedOffering of data.selectedOffering" [value]="selectedOffering">{{selectedOffering}}
         </mat-option>
       </mat-select>
        </mat-form-field>
  </div>

   <div class="select-option" *ngIf="data.selectedPackage?.length && data.from =='USER_PREFERENCES'">
    <mat-label class="label-text">{{"Navigation.Menu.UserPreferences.Dialog.Form.Method" | translate}}</mat-label>
    <mat-form-field class="form-field-option" appearance="outline" floatLabel="never">
      <i class="fas fa-chevron-down"></i>
      <img matSuffix src="../../../../assets/images/filter-pipe.png" width="1px" height="20px">
      <mat-select  name="selectedPackage" class="placeholder-text" [placeholder]="'Navigation.Menu.UserPreferences.Dialog.Form.Method.Placeholder' | translate" disableOptionCentering
       [(ngModel)]="data.userPrefPackage"
       (selectionChange)="getReleventFunctions($event.value)"
       [disabled]="noPublishedPackage">
       <mat-option class="mat-opt-text" *ngFor="let selectedPackage of data.selectedPackage" [value]="selectedPackage.name">{{selectedPackage.display_nm}}
       </mat-option>
     </mat-select>
      </mat-form-field>
      <ng-container *ngIf="noPublishedPackage">
      <p style="font-size: 12px"> {{noPublishedPkgMsg}} </p>
    </ng-container>
</div>
<div class="select-option" *ngIf="data.from =='USER_PREFERENCES'">
  <mat-label class="label-text">{{"Navigation.Menu.UserPreferences.Dialog.Form.Function" | translate}}</mat-label>
  <mat-form-field class="form-field-option" appearance="outline" floatLabel="never">
    <i class="fas fa-chevron-down"></i>
    <img matSuffix src="../../../../assets/images/filter-pipe.png" width="1px" height="20px">
    <mat-select name="selectedFunctions" class="placeholder-text" [placeholder]="'Navigation.Menu.UserPreferences.Dialog.Form.Function.Placeholder' | translate" disableOptionCentering
     [(ngModel)]="data.userPrefFunction" [disabled]="noPublishedPackage || !data.selectedFunctions?.length>0" (selectionChange)="hideMessage($event.value)">
      <mat-option class="mat-opt-text" *ngFor="let selectedFunction of data.selectedFunctions" [value]="selectedFunction">{{selectedFunction}}
      </mat-option>
    </mat-select>
    </mat-form-field>
</div>

  </mat-dialog-content>
  <hr class="divider divider-bottom" *ngIf="data.from =='USER_PREFERENCES' || data.from =='CREATE_PROJECT' || data.from == 'DOWNLOAD_REPORTS' || data.from == 'MSTEAMS_PROVISION'"/>
  <hr class="divider divider-bottom-delete" *ngIf=" data.from == 'PROJECT_USERS_DELETE' || data.from=='PROJECT_SOFT_DELETE' || data.from =='ADMIN_DELETE' || data.from =='DOWNLOAD_USER_STORY_SPRINT' || data.from == 'GENERATE_SPRINT' || data.from == 'DELETE_ALL_NOTIFICATIONS'"/>
  <hr class="divider divider-bottom-tool" *ngIf="data.from == 'PROVISION_TOOL'">
  <mat-dialog-actions align="end" class="custom-mat-dialog-actions" [ngStyle]="{'height': data.from == 'USER_PREFERENCES' ? '0%' : '100%'}"  *ngIf="data && data.from !='MARKETPLACE_SOLUTIONS'">
   <!-- Button - User Preferences -->
    <button *ngIf="(data.from == 'USER_PREFERENCES' && data?.cancelBtn == 'Navigation.Menu.UserPreferences.Dialog.Button.Cancel')" [disabled]="(!userPrefDataFound && !noPublishedPackage) " mat-raised-button (click)="close()"   [ngStyle]="{display: data?.display? data?.display:'block'}"
    [ngClass]="{ 'btn-cancel' : data?.buttonCancelClass, 'btn-continue' : data?.buttonContinueClass}">
      {{ (data?.cancelBtn ? data?.cancelBtn : 'CreateProject.Dialog.Client.Button.OK') | translate }}
    </button>
    <button *ngIf="data.from == 'USER_PREFERENCES'" mat-raised-button [disabled]="!hideApplyBtn" (click)="apply(data.userPrefPackage, data.userPrefFunction, data.userPrefOffering)"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
    [ngClass]="{'btn-continue btn-apply' : data?.buttonContinueClass}">
      {{ (data?.buttonText ? data?.buttonText : 'CreateProject.Dialog.Client.Button.OK') | translate }}
    </button>
        <!-- Button - User Preferences -->
    <!--Button for download reports-->
    <button class="download-cancel-button" *ngIf="(data.from == 'DOWNLOAD_REPORTS' && data?.cancelBtn == 'Cancel')" [disabled]="false" mat-raised-button (click)="close()"   [ngStyle]="{display: data?.display? data?.display:'block'}"
    [ngClass]="{ 'btn-cancel' : data?.buttonCancelClass, 'btn-continue' : data?.buttonContinueClass}">
      {{ data?.cancelBtn ? data?.cancelBtn : 'OK' }}
    </button>

    <div [matTooltip]="projectPackagesAndStatus.currentValidationError" [matTooltipDisabled]="projectPackagesAndStatus.enableDownloadButton" matTooltipClass="mat-tooltip-download-validation">
    <button class="download-button" *ngIf="data.from == 'DOWNLOAD_REPORTS'" mat-raised-button [disabled]="!projectPackagesAndStatus.enableDownloadButton" (click)="downloadReportOnClick()"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
    [ngClass]="{'btn-continue btn-apply' : data?.buttonContinueClass}">
      {{ data?.buttonText ? data?.buttonText : 'OK' }}
    </button>
    </div>
    <!---->

    <!--Button for ms teams-->
    <button class="msCancel-button" *ngIf="(data.from == 'MSTEAMS_PROVISION' && data?.cancelBtn == 'Global.Common.Button.Cancel')" [disabled]="false" mat-raised-button (click)="closePopup()">
      {{'Global.Common.Button.Cancel' | translate }}
    </button>
    <div>
    <button class="msRequest-button" *ngIf="data.from == 'MSTEAMS_PROVISION'" mat-raised-button [disabled]="!isChannelSelected" (click)="closeMSChannelPopup()"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}" style="color: white;">
      {{'Shared.CommonDialogBox.DeliveryTool.Label.RequestText' | translate }}
    </button>
    </div>
    <!---->

<!--Button for ms teams swap-->
<button class="msCancel-button" *ngIf="(data.from == 'MSTEAMS_SWAP' && data?.cancelBtn == 'Global.Common.Button.Cancel')" [disabled]="false" mat-raised-button (click)="closePopup()">
  {{'Global.Common.Button.Cancel' | translate }}
</button>
<button class="msCancel-button" *ngIf="(data.from == 'MSTEAMS_SWAP_CONFIRMDATA' && data?.cancelBtn == 'Global.Common.Button.Cancel')" [disabled]="false" mat-raised-button (click)="closePopup()">
  {{'Global.Common.Button.Cancel' | translate }}
</button>
<div>
  <button class="msSwapsave-button" *ngIf="data.from == 'MSTEAMS_SWAP_CONFIRMDATA'" mat-raised-button [disabled]="!isDataMigratedConfirm " (click)="closeMSSwapConfirmChannelPopup()"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}" [ngClass]="{'disabled-button': !isDataMigratedConfirm }"  style="color: white;">
    {{'Global.Common.Button.Confirm' | translate }}
  </button>
</div>
<div>
  <button class="msSwapsave-button" *ngIf="data.from == 'MSTEAMS_SWAP' && !isMoreThan10GB && IsGenerativeAIFolderFound" mat-raised-button [disabled]="!isChannelSelected " (click)="closeMSSwapChannelPopup()"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}" [ngClass]="{'disabled-button': !isChannelSelected }"  style="color: white;">
    {{'Global.Common.Button.Save' | translate }}
  </button>

  <button class="msSwapsave-button" *ngIf="data.from == 'MSTEAMS_SWAP' && !isMoreThan10GB && !IsGenerativeAIFolderFound" 
          mat-raised-button [disabled]="true"
          (click)="closeMSSwapChannelPopup()" 
          cdkFocusInitial 
          [ngStyle]="{display: data?.display? data?.display:'block'}" 
          [ngClass]="{'disabled-button': !IsGenerativeAIFolderFound }" 
          [matTooltip]="'DeliveryTools.Dailog.Label.MSTeamsSwap.Disable.ToolTip' | translate" 
          style="color: white;">
    {{'Global.Common.Button.Save' | translate }}
  </button>

<button class="msSwapsave-button" *ngIf="data.from == 'MSTEAMS_SWAP' && isMoreThan10GB" 
        mat-raised-button 
        [disabled]="!isChannelSelected || !isDataMigrated" 
        (click)="closeMSSwapChannelPopup()"  
        cdkFocusInitial  
        [ngStyle]="{display: data?.display ? data?.display : 'block'}" 
        [ngClass]="{'disabled-button': !isChannelSelected || !isDataMigrated}"  
        style="color: white;" 
        [matTooltip]="'Shared.CommonDialogBox.Label.MSTeamsSwap.PleaseCompleteTheDataMigration' | translate" 
        matTooltipPosition="above">
  {{'Global.Common.Button.Save' | translate }}
</button>
</div>
<!---->

       <!-- Button - Create PRoject-->
       <button *ngIf="(data.from == 'CREATE_PROJECT' && data?.cancelBtn == 'CreateProject.ProjectDetails.Cancel')"  mat-raised-button (click)="close()"   [ngStyle]="{display: data?.display? data?.display:'block'}"
       [ngClass]="{ 'btn-cancel' : data?.buttonCancelClass, 'btn-continue' : data?.buttonContinueClass}">
         {{ (data?.cancelBtn ? data?.cancelBtn : 'CreateProject.Dialog.Client.Button.OK') | translate }}
       </button>
       <button *ngIf="data.from == 'CREATE_PROJECT' && data.buttonContinueClass" mat-raised-button (click)="discard()"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
       [ngClass]="{'btn-continue btn-apply' : data?.buttonContinueClass}">
         {{ (data?.buttonText ? data?.buttonText : 'CreateProject.Dialog.Client.Button.OK') | translate}}
       </button>
       <!-- Button - Create Projects -->
     <!-- Button - Delete Users -->
     <button *ngIf="(data.from == 'PROJECT_USERS_DELETE' && data?.cancelBtn == 'Global.Common.Button.Cancel')"  mat-raised-button (click)="close()"   [ngStyle]="{display: data?.display? data?.display:'block'}"
     [ngClass]="{ 'btn-cancel' : data?.buttonCancelClass, 'btn-continue' : data?.buttonContinueClass}">
       {{ (data?.cancelBtn ? data?.cancelBtn : 'CreateProject.Dialog.Client.Button.OK') | translate}}
     </button>
     <button class="del-confirmbtn" *ngIf="(data.from == 'DELETE_ALL_NOTIFICATIONS' && data?.cancelBtn == 'Navigation.Menu.Notification.Dialog.Cancel')"  mat-raised-button (click)="closePopup()"   [ngStyle]="{display: data?.display? data?.display:'block'}"
     [ngClass]="{ 'btn-cancel' : data?.buttonCancelClass, 'btn-continue' : data?.buttonContinueClass}">
       {{ (data?.cancelBtn ? data?.cancelBtn : 'CreateProject.Dialog.Client.Button.OK') | translate }}
     </button>
     <button *ngIf="data.from == 'PROJECT_USERS_DELETE'" mat-raised-button  (click)="deleteProjectUser(data.projectId, data.userId, data.deleteUserId)"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
     [ngClass]="{'btn-continue btn-apply' : data?.buttonContinueClass}">
       {{ (data?.buttonText ? data?.buttonText : 'CreateProject.Dialog.Client.Button.OK') | translate }}
     </button>
     <!-- Button - Delete Users -->

      <!-- Button - Delete Project -->
      <button *ngIf="(data.from == 'PROJECT_SOFT_DELETE' && data?.cancelBtn == 'CreateProject.ProjectDetails.Cancel')"  mat-raised-button (click)="close()"   [ngStyle]="{display: data?.display? data?.display:'block'}"
      [ngClass]="{ 'btn-cancel' : data?.buttonCancelClass, 'btn-continue' : data?.buttonContinueClass}">
        {{ (data?.cancelBtn ? data?.cancelBtn : 'CreateProject.Dialog.Client.Button.OK') | translate }}
      </button>
      <button *ngIf="data.from == 'PROJECT_SOFT_DELETE'" mat-raised-button  (click)="softDeleteProject(data.projectId, data.userId)"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
      [ngClass]="{'btn-continue btn-apply' : data?.buttonContinueClass}">
        {{ (data?.buttonText ? data?.buttonText : 'CreateProject.Dialog.Client.Button.OK') | translate}}
      </button>
      <!-- Button - Delete Project -->

      <button *ngIf="data.from == 'DOWNLOAD_USER_STORY_SPRINT'" mat-raised-button  (click)="close()"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
      [ngClass]="{'btn-continue btn-apply' : data.buttonContinueClass}">
      {{ (data?.buttonText ? data?.buttonText : 'Global.Common.Button.Ok' ) | translate}}
      </button>

      <button *ngIf="data.from == 'GENERATE_SPRINT'" mat-raised-button  (click)="close()"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
      [ngClass]="{'btn-continue btn-apply' : data.buttonContinueClass}">
      {{ data?.buttonText ? data?.buttonText : 'OK' }}
      </button>

      <button *ngIf="data?.title == 'Provision Status' && data?.buttonText == 'Global.Common.Button.Continue' && data && data.from == 'PROVISION_TOOL' && data?.messageItems[0] != 'MS Teams'&& data?.messageItems[0] != 'Test AI'" mat-raised-button (click)="data.continue(); closePopup();"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
      [ngClass]="{ 'btn-ok' : data?.btnokclass, 'btn-continue provision' : data?.buttonContinueClass}">
        {{ (data?.buttonText ? data?.buttonText : 'Global.Common.Button.Ok') | translate }}
      </button>

      <button *ngIf="data?.title == 'Provision Status' && data?.buttonText == 'Global.Common.Button.Continue' && data && data.from == 'PROVISION_TOOL' && data?.messageItems[0] == 'MS Teams'" mat-raised-button (click)=" closePopup();"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
      [ngClass]="{ 'btn-ok' : data?.btnokclass, 'btn-continue provision' : data?.buttonContinueClass}">
        {{ (data?.buttonText ? data?.buttonText : 'Global.Common.Button.Ok') | translate }}
      </button>

      <button *ngIf="!(data?.title == 'Provision Status' && data?.buttonText == 'Global.Common.Button.Continue') && data && data.from == 'PROVISION_TOOL'  && data?.title != ecTitle && data?.title != soTitle && data?.title != testAITitle" mat-raised-button (click)="close()" cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
      [ngClass]="{ 'btn-ok' : data?.btnokclass, 'btn-continue' : data?.buttonContinueClass, 'btn-cancel' : data?.buttonCancelClass}">
        {{ (data?.buttonText ? data?.buttonText : 'Global.Common.Button.Ok') | translate }}
      </button>

      <button *ngIf="(data.from == 'PROVISION_TOOL' && data?.cancelBtn == 'Global.Common.Button.Cancel' && (data?.title == ecTitle || data?.title == soTitle || data?.title == testAITitle))"  mat-raised-button (click)="closePopup()"   [ngStyle]="{display: data?.display? data?.display:'block'}"
      [ngClass]="{ 'btn-cancel' : data?.buttonCancelClass, 'btn-continue' : data?.buttonContinueClass}">
        {{ (data?.cancelBtn ? data?.cancelBtn : 'Global.Common.Button.Ok') | translate }}
      </button>

      <button *ngIf="(data.from == 'RETIRE_TAXONOMY' && data?.cancelBtn == 'Cancel')"  mat-raised-button (click)="closePopup()"   [ngStyle]="{display: data?.display? data?.display:'block'}"
      [ngClass]="{ 'btn-cancel' : data?.buttonCancelClass, 'btn-continue' : data?.buttonContinueClass}">
        {{ (data?.cancelBtn ? data?.cancelBtn : 'Global.Common.Button.Ok') | translate }}
      </button>

      <button *ngIf="(data?.title == ecTitle  || data?.title == soTitle || data?.title == testAITitle) && (data?.buttonText == 'Global.Common.Button.Confirm') && data && data.from == 'PROVISION_TOOL'" mat-raised-button (click)="close();" cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
      [ngClass]="{ 'btn-ok' : data?.btnokclass, 'btn-continue provision' : data?.buttonContinueClass}">
        {{ (data?.buttonText ? data?.buttonText : 'Global.Common.Button.Ok') | translate }}
      </button>

       <!-- Button - Delete Admin -->
       <button *ngIf="(data.from == 'ADMIN_DELETE' && data?.cancelBtn == 'Cancel')"  mat-raised-button (click)="close()"   [ngStyle]="{display: data?.display? data?.display:'block'}"
       [ngClass]="{ 'btn-cancel' : data?.buttonCancelClass, 'btn-continue' : data?.buttonContinueClass}">
         {{ (data?.cancelBtn ? data?.cancelBtn : 'Global.Common.Button.Ok') | translate }}
       </button>
       <button *ngIf="data.from == 'ADMIN_DELETE'" mat-raised-button  (click)="deleteAdmin()"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
       [ngClass]="{'btn-continue btn-apply' : data?.buttonContinueClass}">
         {{( data?.buttonText ? data?.buttonText : 'Global.Common.Button.Ok' ) | translate}}
       </button>
       <!-- Button - Delete Admin -->

    <button *ngIf="(data.from == 'FinalizeSprint' && data?.cancelBtn == 'Cancel')" class="btn-cancel" mat-raised-button (click)="closePopupFinalizeSprint()"   [ngStyle]="{display: data?.display? data?.display:'block'}"
    >
      {{ (data?.cancelBtn ? data?.cancelBtn : 'Global.Common.Button.Ok') | translate }}
    </button>

    <button *ngIf="(data.from != 'DOWNLOAD_REPORTS') && (data.from != 'MSTEAMS_PROVISION') && (data.from != 'MSTEAMS_SWAP') && (data.from != 'MSTEAMS_SWAP_CONFIRMDATA')  && !(data?.title == 'Provision Status' && data?.buttonText == 'Global.Common.Button.Continue')" mat-raised-button (click)="close()" [hidden]="data.from== 'USER_PREFERENCES' || data.from == 'PROJECT_USERS_DELETE' || data.from == 'CREATE_PROJECT' || data.from == 'PROJECT_SOFT_DELETE' || data.from =='ADMIN_DELETE' || data.from =='DOWNLOAD_USER_STORY_SPRINT' || data.from =='GENERATE_SPRINT' || data.from =='PROVISION_TOOL'" cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
    [ngClass]="{ 'btn-ok' : data?.btnokclass, 'btn-continue provision' : data?.buttonContinueClass}">
      {{ (data?.buttonText ? data?.buttonText : 'Global.Common.Button.Ok') | translate }}
    </button>

    <button *ngIf="data?.title == 'Provision Status'  && data?.buttonText == 'Global.Common.Button.Continue'" [hidden]="data.from == 'PROVISION_TOOL'" mat-raised-button (click)="data.continue(); closePopup();"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
    [ngClass]="{ 'btn-ok error-style' : data?.btnokclass, 'btn-continue' : data?.buttonContinueClass}">
      {{ (data?.buttonText ? data?.buttonText : 'Global.Common.Button.Ok') | translate}}
    </button>
  </mat-dialog-actions>

  <div class="multicolor-band" [ngStyle]="{'position': data && data.from =='MARKETPLACE_SOLUTIONS' ? 'absolute' : ''}" [hidden]="data.from == 'USER_PREFERENCES' || data.from =='PROJECT_USERS_DELETE'  || data.from == 'CREATE_PROJECT' || data.from =='PROJECT_SOFT_DELETE' || data.from=='ADMIN_DELETE' || data.from =='DOWNLOAD_USER_STORY_SPRINT' || data.from =='PROVISION_TOOL' || data.from =='GENERATE_SPRINT' || data.from == 'RETIRE_TAXONOMY' || data.from == 'DELETE_ALL_NOTIFICATIONS' || data.from == 'DOWNLOAD_REPORTS' || data.from == 'MSTEAMS_PROVISION' || data.from == 'MSTEAMS_SWAP' || data.from == 'MSTEAMS_SWAP_CONFIRMDATA'">
    <img class="rectangle" src="../../assets/bottom_color.png" />
  </div>
</div>
